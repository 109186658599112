<template>
  <div>
    <page-section class="py-16 px-6">

      <v-row>
        <v-col v-if="SM" cols="12" md="6" >
          <v-img src="/library/img/home/business_upgrade.png" alt="WeStudy Upgrade: просування курсів"></v-img>
        </v-col>
        <v-col cols="12" md="6" class="pr-4">
          <div class="fill-height d-flex align-center" >
            <div>

              <h1 class="wsDARKER" style="font-size: 42px"
                  :style="!SM ? `font-size: 32px; line-height: auto;` : `font-size: 19px; line-height: 1.5;`"
              >{{ $t('homepage.landings.upgrade.title') }}</h1>
              <h4 class="wsDARKER font-weight-regular mt-4">{{ $t('homepage.landings.upgrade.text1') }}</h4>
              <h4 class="wsDARKER font-weight-regular mt-6">{{ $t('homepage.landings.upgrade.text2') }}</h4>
              <ws-button  @click="addLead" :block="SM" class="mt-6" label="homepage.landings.upgrade.order_consultation"  />
            </div>
          </div>

        </v-col>

        <v-col v-if="!SM" cols="12" md="6" class="pl-16">
          <v-img src="/library/img/home/business_upgrade.png" alt="WeStudy Upgrade: просування курсів"></v-img>
        </v-col>
      </v-row>

    </page-section>


    <page-section
        title="homepage.landings.upgrade.features_title"
        class="py-16 px-4">

      <div>

        <v-row class="mt-10">
          <v-col v-for="(item,i) in featuresSelect" :key="i" cols="12" md="4" >
            <v-sheet
                style="border: 1px solid var(--wsBACKGROUND)"
                class="pa-6 py-8 wsRoundedSemi fill-height"
            >
              <v-icon :color="wsATTENTION"> {{ item.icon}}</v-icon>
              <h4 class="wsDARKER font-weight-regular mt-4">{{ $t(item.text) }}</h4>
            </v-sheet>
          </v-col>
        </v-row>

      </div>

    </page-section>

    <page-section class="py-16 px-4">

      <v-row>
        <v-col cols="12" md="6" >
          <v-sheet :color="wsLIGHTCARD" class="wsRoundedSemi d-flex flex-column pa-6 fill-height">
            <h2 class="wsDARKER " :style="SM ? 'font-size : 20px' : null">{{ $t('homepage.landings.upgrade.additional.title1') }}</h2>
            <h4 class="wsDARKER font-weight-regular mt-4">{{ $t('homepage.landings.upgrade.additional.title1_text1') }}</h4>
            <h4 class="wsDARKER font-weight-regular mt-2">{{ $t('homepage.landings.upgrade.additional.title1_text2') }}</h4>
            <h4 class="wsDARKER font-weight-regular mt-2">{{ $t('homepage.landings.upgrade.additional.title1_text3') }}</h4>
            <div class="flex-grow-1"></div>
            <v-img alt="WeStudy Online Courses Platform" class="mt-4" src="@/assets/illustrations/consultation.png" />
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6" >
          <v-sheet :color="wsATTENTION" class="wsRoundedSemi pa-6 d-flex flex-column fill-height">
            <h2 class="wsWHITE" :style="SM ? 'font-size : 20px' : null">{{ $t('homepage.landings.upgrade.additional.title2') }}</h2>
            <h4 class="wsWHITE font-weight-regular mt-4">{{ $t('homepage.landings.upgrade.additional.title2_text') }}</h4>
            <div class="flex-grow-1"></div>
            <v-img alt="WeStudy Платформа для створення курсів" class="mt-4" src="@/assets/illustrations/updates.png" />

          </v-sheet>
        </v-col>
      </v-row>

    </page-section>

    <page-section
        title="homepage.landings.upgrade.services.title"
        class="py-16 px-4"
    >

      <v-row class="mt-10">
        <v-col v-for="(item,i) in servicesSelect" :key="i" cols="12" md="4">
          <v-sheet :color="wsLIGHTCARD" class="pa-6 wsRoundedSemi fill-height d-flex flex-column">
            <h3 class="wsDARKER">{{ $t(`homepage.landings.upgrade.services.${item.type}.title`) }}</h3>
            <h1 class="wsDARKER mt-2">{{ item.price }}$</h1>

            <v-divider class="my-5" style="border-color : var(--wsBACKGROUND)" />

            <h4 v-for="featureNumber in item.features" :key="featureNumber + i + 'features'"
                class="wsDARKER font-weight-regular d-flex  align-stretch"
                :class="[{'mt-3' : true}]"
            >
              <v-icon :color="wsATTENTION" class="mr-2 ">mdi-check</v-icon>
              {{  $t(`homepage.landings.upgrade.services.${item.type}.feature${featureNumber}`) }}
            </h4>

            <h4 class="wsDARKER mt-5" v-if="item.type === 'ads'">
             {{ $t(`homepage.landings.upgrade.services.ads.budget`) }}
            </h4>
            <v-divider class="my-5" style="border-color : var(--wsBACKGROUND)" />

            <h4 class="wsDARKER font-weight-regular mt-2">{{ $t(`homepage.landings.upgrade.services.${item.type}.description`) }}</h4>

            <h4 class="wsACCENT mt-10">{{ $t('homepage.landings.upgrade.services.bonus') }}</h4>
            <h4 class="wsACCENT font-weight-regular mt-2">{{ $t(`homepage.landings.upgrade.services.${item.type}.bonus`) }}</h4>

            <div class="flex-grow-1"/>

            <div>
              <ws-button @click="addLead(item.type)" height="40" label="homepage.landings.upgrade.services.order" class="mt-6" block></ws-button>
            </div>

          </v-sheet>
        </v-col>
      </v-row>

    </page-section>

    <page-section
        class="py-16 px-4"
        :background-color="wsACCENT"
    >
      <h1 class="wsWHITE text-center"
          :style="!SM ? `font-size: 32px; line-height: auto;` : `font-size: 19px; line-height: 1.5;`"
      >{{ $t('homepage.landings.upgrade.packages.title') }}</h1>


      <v-row class="mt-10">
        <v-col v-for="(item,i) in packagesSelect" :key="i" cols="12" md="3">
          <v-sheet :color="wsLIGHTCARD" class="pa-6 wsRoundedSemi fill-height d-flex flex-column">
            <h3 class="wsDARKER">{{ item.title }}</h3>

            <div class="d-flex align-center">
              <h1 class="wsDARKER mt-2 mr-2">{{ item.price }}$</h1>
              <h3 class="wsDARKER mt-2" style="text-decoration: line-through">{{ item.oldPrice }}$</h3>
            </div>

            <v-divider class="my-5" style="border-color : var(--wsBACKGROUND)" />

            <h4 v-for="featureNumber in item.features" :key="featureNumber + i + 'features'"
                class="wsDARKER font-weight-regular d-flex  align-stretch"
                :class="[{'mt-3' : true}]"
            >
              <v-icon :color="wsATTENTION" class="mr-2 ">mdi-check</v-icon>
              {{  $t(`homepage.landings.upgrade.packages.${item.type}.feature${featureNumber}`) }}
            </h4>

            <div class="flex-grow-1"/>

            <div>
              <ws-button @click="addLead(item.type)" height="40" label="homepage.landings.upgrade.services.order" class="mt-6" block></ws-button>
            </div>

          </v-sheet>
        </v-col>
      </v-row>

      <v-sheet  :color="wsATTENTION" class="align-center wsRoundedSemi pa-6 justify-space-between my-16"
                :class="[{'d-flex' : !SM}]"
      >
        <div>
          <h4 class="wsWHITE">{{   $t('homepage.landings.upgrade.consult.title')  }}</h4>
          <h4 class="wsWHITE font-weight-regular" >{{  $t('homepage.landings.upgrade.consult.text') }}</h4>
        </div>

        <ws-button
            @click="addLead(true)"
            label="homepage.landings.upgrade.order_consultation"
            :color="wsWHITE"
            outlined
            :block="SM"
            :class="[{'mt-3' : SM}]"
        />

      </v-sheet>

      <h1
          class="text-center mt-10 mb-6 wsWHITE">
        {{ $t('homepage.pricing.faq.title') }}
      </h1>

      <div class="d-flex justify-center pb-16">
        <div    >
          <div v-for="(item, i ) in faqItems" :key="`faq_${i}`">

            <v-sheet
                class="px-4 wsRoundedHalf mt-2"
                :max-width="!SM ? 782 : null"
                :width="!SM ? 782 : null"
            >
              <div
                  @click="item.expanded = !item.expanded"
                  class="d-flex justify-space-between align-center pointer "
                  :style="`height: ${!SM ? 72 : 70}px`"
              >
                <h3 class="mr-3"  :style="`color : ${wsDARKER}; ${SM ? 'font-size: 13px;' : ''} ` "> {{ $t(item.title) }} </h3>
                <v-icon
                    :style="item.expanded ? 'transform: rotate(180deg)' : null "
                    style="transition: all 0.3s ease;"
                    :color="wsDARKER">mdi-chevron-down</v-icon>
              </div>
              <v-expand-transition>
                <div v-if="item.expanded" class="pb-5">
                  <v-divider :style="`border-color : ${wsBACKGROUND}`" />
                  <h4 :style="`color : ${wsDARKER};  ${SM ? 'font-size: 13px;' : ''} `" class="font-weight-regular pt-5 px-4">{{ $t(item.text) }}</h4>
                </div>
              </v-expand-transition>
            </v-sheet>
          </div>

        </div>
      </div>


    </page-section>


    <home-contact-form-dialog
        v-model="displayDialog"
        :service="consultService"
        upgrade
    />

  </div>
</template>


<script>
import homeContactFormDialog from "@/components/pages/homepage_westudy/UI/homeContactFormDialog.vue";

export default {
  name: "homePayedServices",
  components: {homeContactFormDialog},
  data() {
    return {
      consultService : null,
      displayDialog : false,
      faqItems : [
        { title : 'homepage.landings.upgrade.faq.question_1.title' , text : 'homepage.landings.upgrade.faq.question_1.text' , expanded : true },
        { title : 'homepage.landings.upgrade.faq.question_2.title' , text : 'homepage.landings.upgrade.faq.question_2.text' , expanded : false },
        { title : 'homepage.landings.upgrade.faq.question_3.title' , text : 'homepage.landings.upgrade.faq.question_3.text' , expanded : false },
        { title : 'homepage.landings.upgrade.faq.question_4.title' , text : 'homepage.landings.upgrade.faq.question_4.text' , expanded : false },
        { title : 'homepage.landings.upgrade.faq.question_5.title' , text : 'homepage.landings.upgrade.faq.question_5.text' , expanded : false },
        { title : 'homepage.landings.upgrade.faq.question_6.title' , text : 'homepage.landings.upgrade.faq.question_6.text' , expanded : false },
        { title : 'homepage.landings.upgrade.faq.question_7.title' , text : 'homepage.landings.upgrade.faq.question_7.text' , expanded : false },
        { title : 'homepage.landings.upgrade.faq.question_8.title' , text : 'homepage.landings.upgrade.faq.question_8.text' , expanded : false },
        { title : 'homepage.landings.upgrade.faq.question_9.title' , text : 'homepage.landings.upgrade.faq.question_9.text' , expanded : false }
      ],
      packagesSelect : [
        { type : 'web_ads',
          title : "Web Upgrade",
          price : 550,
          oldPrice : 650,
          economy : 100,
          features : 2
        },
        { type : 'web_courses',
          title : "Start Upgrade",
          price : 600,
          oldPrice : 700,
          economy : 100,
          features : 2
        },
        { type : 'ads_courses',
          title : "Ads Upgrade",
          price : 750,
          oldPrice : 850,
          economy : 100,
          features : 2
        },
        { type : 'full',
          title : "Maximum Upgrade",
          price : 950,
          oldPrice : 1100,
          economy : 150,
          features : 3
        },
      ],
      servicesSelect : [
        { type : 'site',
          price : 250,
          features : 6
        },
        { type : 'ads',
          price : 400,
          features: 7,
        },
        { type : 'courses',
          price : 450,
          features: 10,
        }
      ],
      featuresSelect : [
        { icon : 'mdi-chart-timeline-variant' ,
          text : 'homepage.landings.upgrade.feature1'
        },
        { icon : 'mdi-puzzle-edit-outline' ,
          text : 'homepage.landings.upgrade.feature2'
        },
        { icon : 'mdi-google-ads' ,
          text : 'homepage.landings.upgrade.feature3'
        },
        { icon : 'mdi-wrench' ,
          text : 'homepage.landings.upgrade.feature4'
        },
        { icon : 'mdi-credit-card-check-outline' ,
          text : 'homepage.landings.upgrade.feature5'
        },
        { icon : 'mdi-email-check-outline' ,
          text : 'homepage.landings.upgrade.feature6'
        }
      ]
    }
  },
  methods : {
    addLead(service = 'consult') {
      this.consultService = service
      this.displayDialog = true
    }
  }
}
</script>



<style scoped>

</style>